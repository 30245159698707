export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [~5],
		"/api/metadata/og-images/preview": [10,[4]],
		"/(app)/blog": [6,[2,3]],
		"/(app)/blog/[uid]": [~7,[2,3]],
		"/(app)/home": [~8,[2]],
		"/(app)/privacy-policy": [~9,[2]],
		"/slice-simulator": [11]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';